import React from "react";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import LandingPage from "./LandingPage/LandingPage";

export default () => (
    <>
        <GatsbySeo
            title='Home' />
        <LandingPage/>
    </>
);
